td > input[type='checkbox'],
th > input[type='checkbox'] {
  height: 18px;
  width: 18px;
}

.product-img {
  height: 45px;
  width: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}
.product-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-bs-table table .th {
  font-size: 16px;
  font-weight: normal;
}
.react-bs-table table td a {
  display: inline-block !important;
}
.react-bs-table .react-bs-container-header > table > thead > tr > th {
  font-size: 14px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #6e6cce;
  border-color: #6e6cce;
}
.pagination > li > a,
.pagination > li > span {
  color: #6e6cce;
}

.product-premium {
  height: 13px;
  margin-left: 8px;
}

.product-link {
  height: 13px;
  margin-left: 8px;
}

.ProductsListings__loading {
  margin-top: 40px;
}

.ProductsListings__btn_info {
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
}

.ProductsListings__btn {
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
}

.ProductsListings__btn:hover {
  background-color: #6342ff !important;
  border: 0.5px solid #3e4990 !important;
}

.ProductsListings__is_disabled {
  color: currentColor;
  pointer-events: none;
  opacity: 0.5;
}

.ProductsListings__sort_icon {
  font-size: 13px;
  margin-left: 5px;
}

.ProductsListings__sort_title {
  cursor: pointer;
}
