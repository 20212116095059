.Orders__filters,
.Orders__searchbar {
  display: flex;
  justify-content: flex-end;
}
.Orders__filters label {
  margin-left: 16px;
}
.Orders__filters label span {
  font-size: 13px !important;
  font-family: Avenir, Nunito;
}

.Orders__searchbar button {
  background-color: #6342ff !important;
}

.Orders__right_tools {
  float: right;
}

button.Orders__download_csv_button_action {
  margin-top: 16px;
  font-weight: 500;
}
