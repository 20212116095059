.suggestionsClass {
  background-color: #fff;
  position: absolute;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  margin-top: 3px;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 5px;
  z-index: 99;
}

.activeSuggestionClass {
  background-color: #dbe6f2;
}

.suggestionsClass ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.suggestionsClass ul li {
  cursor: pointer;
  color: #3c4858;
  padding: 4px 0px 4px 4px;
}

.input-height {
  line-height: 30px;
  font-size: 12px;
}
.h-custom {
  line-height: 41px;
}
.h-26 {
  line-height: 26px;
}
