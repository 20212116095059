.Bank-Button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Bank-Button .btn {
    margin: 10px;
    width: auto;
}

.Verify-Account-Text {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 5px;
    color: #373c4a;
}

.Disclaimer-Text {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 5px;
    color: #959aaa;
}

.SaveBank-Button-Disabled {
    cursor: not-allowed;
    opacity: .5;
}