.ThankYouPage__container .row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ThankYouPage__container img {
  width: 100%;
}

.ThankYouPage__container .paragraph-one {
  display: flex;
  justify-content: center;
  font-size: 22px;
  text-align: center;
}

.ThankYouPage__container .paragraph-two {
  display: flex;
  text-align: center;
  font-size: 20px;
}
