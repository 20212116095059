.Registration_form .Select_Spocket__container {
  border-radius: 4px !important;
  border: solid 1px #e7e7e7 !important;
  background-color: #fafbfc !important;
  height: 46px;
}

.Registration_form .Select_Spocket__container .Select-control {
  border: none !important;
  height: 44px !important;
}

.Registration_form .small-text {
  font-size: 11px;
  color: #7c98b6;
}

.Registration_form .error {
  color: #f44336;
}

.Registration_form .form-control {
  border-radius: 4px !important;
  border: solid 1px #e7e7e7 !important;
  background-color: #fafbfc !important;
  height: 46px !important;
}
