.download-orders-csv-modal__container .modal-dialog {
  width: auto;
}

.download-orders-csv-modal__footer_buttons {
  float: right;
}

.download-orders-csv-modal__container .DayPicker {
  margin: 0 auto;
}

@media (max-width: 1224px) {
  .download-orders-csv-modal__container .modal-content .modal-body {
    padding: 24px 0;
  }
  .download-orders-csv-modal__footer_buttons {
    width: 100%;
  }
  .download-orders-csv-modal__footer_buttons button {
    width: 100%;
    margin: 0 0 4px !important;
  }
}

.download-orders-csv-modal__footer_max_months_reached {
  color: #FF0000;
  margin-bottom: 16px;
}
