.no-border {
  border-top: 1px solid transparent !important;
}
.order-header {
  line-height: 26px;
}

.order-header b {
  font-size: 16px;
}

.order-header span,
.order-header a {
  font-weight: 400;
  font-size: 15px;
}

.sample-order-badge {
  background-color: #e8e5fc;
  color: #774edd;
  width: 65px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  padding: 3px;
  display: block;
  margin: 0 auto;
}

.Orders__info {
  border-bottom: 1px solid #d5d7e2;
}

.Orders__info h5 {
  margin-top: 0;
}

.Orders__table .text-left {
  text-align: left;
}
.Orders__table .text-right {
  text-align: right;
}

.Orders__table .Orders__table__totalborder {
  padding-bottom: 22px;
}

.Orders__table td {
  border-top: none !important;
}

.Orders__table th {
  border-bottom: 1px solid #ddd !important;
}

.Orders__table .Orders__table__total {
  border-top: 1px solid #d5d7e2 !important;
  padding-top: 18px;
}

.Order__note__title {
  color: #6342ff;
}

.Order__button_action {
  width: 140px !important;
  font-weight: 500 !important;
}

.Order__button_disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.Order__cancel_button {
  background-color: #f2f2fa !important;
  border-radius: 2px !important;
  border: 1px solid #dadde0 !important;
  color: #413951 !important;
  box-shadow: none !important;
  font-weight: 500 !important;
}

.Order__important-note {
  margin-top: 16px;
}
