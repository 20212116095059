.CalendarDay__selected_span {
  background: #6342ff;
  color: white;
  border: 1px solid #6342ff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #6342ffe8;
  border: 1px double #6342ff;
  color: #fff;
}

.CalendarDay__selected:hover {
  background: #6342ff;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #6342ff;
  border: 1px double #6342ff;
  color: white;
}

.CalendarDay__hovered_span:active {
  background: #2f3ea0;
  border: 1px double #6342ff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #343e80;
  border: 1px double #3e4990;
  color: #fff;
}
