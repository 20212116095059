.side-bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 70px;
  background-color: #17364d;
  padding: 0;
  z-index: 100;
}

.side-bar img {
  height: 15px;
  display: inline-block;
}
.side-bar .side-logo {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: 10px;
}
.side-bar .logo {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #6342ff;
  border-radius: 5px;
  opacity: 1;
}
.side-bar ul {
  padding: 0;
  display: table-cell;
  list-style: none;
}
.side-bar ul li {
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.side-bar ul li a {
  display: inline-block;
  border-radius: 5px;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0);
  font-size: 13px;
  line-height: 35px;
  opacity: 0.4;
  outline: 0 !important;
  text-align: center;
  width: 35px;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.side-bar ul li a:hover,
.side-bar ul li a.active {
  opacity: 1;
  background-color: #29465c;
}

.side-bar .list-container {
  display: table;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 80px;
}

.side-bar .list-top {
  display: table-row;
}
.side-bar .list-top ul {
  vertical-align: top;
}
.side-bar .list-bottom {
  display: table-row;
}
.side-bar .list-bottom ul {
  vertical-align: bottom;
}

.s-tooltip {
  pointer-events: auto !important;
  background-color: #29465c !important;
  opacity: 1 !important;
  border-radius: 2px;
}

.__react_component_tooltip.type-dark.place-right {
  margin-left: 15px;
  padding-left: 15px !important;
}

.__react_component_tooltip.type-dark.place-right::after {
  border-right-color: #29465c !important;
}

.s-tooltip a {
  background-color: transparent !important;
  color: #ffffff !important;
  display: block !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 1 !important;
  opacity: 0.8 !important;
  padding: 7px 0px !important;
  text-align: left !important;
  text-decoration: none !important;
  transition: opacity 0.2s cubic-bezier(0.3, 0.1, 0.58, 1) !important;
  width: auto !important;
}
.s-tooltip a:hover {
  color: #fff !important;
  opacity: 1 !important;
}
.s-tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}
