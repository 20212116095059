.IntroPage__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.IntroPage__container img {
  height: 70px;
}

.IntroPage__body {
  font-size: 18px;
  color: #2d2d30;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 0 40px;
}

.IntroPage__body p {
  margin-bottom: 8px;
}

.IntroPage__title {
  font-size: 25px;
  color: #2d2d30;
  font-weight: 900;
  margin: 40px 0 20px 0;
}

.IntroPage__footer {
  margin-top: 40px;
}
