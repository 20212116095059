h4 {
  font-weight: 500;
  size: 18px;
  line-height: 23px;
  border-bottom: 23px;
  color: #2a2d39;
}

.FormGroup__product__title {
  margin-bottom: 30px;
}

.FormGroup__product__status {
  margin-bottom: 17px;
}

.FormGroup__product__organize {
  margin-bottom: 10px;
}

.FormGroup__product__personalized {
  margin-bottom: 7px;
}

.popover .column-updater-input {
  width: 35% !important;
}

.popover .column-updater-select {
  width: 65% !important;
  height: 30px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.added-img {
  width: 143px;
  height: 126px;
  display: inline-block;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin-right: 7px;
  line-height: 124px;
  border: 1px solid #e3e3e3;
  position: relative;
}

.added-img:last-child {
  margin-right: 7px;
}

.added-img:after {
  content: ' ';
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.added-img:hover:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.added-img:hover .Images_form__delete_button_icon,
.added-img:hover .Images_form__delete_button {
  display: block;
}

.added-img img {
  display: none;
  transform: rotate(45);
}

.Images_form__delete_button {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
  display: none;
  z-index: 2;
}

.Image_form__cover {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  color: #413951;
  position: relative;
  top: -46px;
  left: 7px;
  padding: 4px;
  font-size: 11px;
  font-weight: 600;
}

.Images_form__delete_button_icon {
  position: absolute;
  right: 7px;
  top: 7px;
  display: none;
  z-index: 1;
  font-size: 18px;
}

.Images_form__delete_button_icon:hover {
  display: block;
}

.Images_form__delete_button:hover {
  display: block;
}

.img-delete {
  width: 25px;
  height: 25px;
  padding: 5px;
  display: block !important;
  opacity: 0;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 99;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.added-img:hover .img-delete {
  opacity: 1 !important;
}

.tgl-label span {
  font-size: 12px;
  line-height: 20px;
  position: relative;
  display: inline-block;
  margin-left: 10px;
  top: -5px;
}
.tgl-label-before {
  margin-right: 10px;
}
.tgl {
  display: none;
}
.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: inline-block;
  margin: 0;
  width: 40px;
  height: 20px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: '';
  width: 16px;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 18px;
}

.tgl-light + .tgl-btn {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #6371c7;
}

.tags-container {
  border: 1px solid #d5d7e2;
  border-radius: 3px;
  max-width: 100%;
  min-height: 34px;
  background-color: #eff0f5;
  padding: 3px;
  padding-top: 0px;
}

.tags-container.disabled {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}

.tags-container .form-control {
  display: inline;
  width: auto;
}
.tag-input-container {
  margin-top: 3px;
  display: inline-block;
  position: relative;
}
.tag-input-container input {
  background-color: white !important;
  border: 1px solid #d5d7e2;
  width: 76px;
  font-size: 10px;
  height: auto;
  height: 26px;
}
.tag-input-container input:focus {
  background-color: white !important;
  border: 1px solid #d5d7e2;
}
.tag-style {
  margin-top: 3px;
  display: inline-block;
  font-size: 12px;
  line-height: 23px;
  margin-right: 3px;
  padding: 0px 4px;
  color: #487fa6;
  background: #dbe6f2;
  border-radius: 3px;
  border: 1px solid #bad0e1;
}
.remove-btn {
  margin-left: 4px;
  cursor: pointer;
}

select.input-lg {
  height: 34px !important;
  line-height: 24px;
  font-size: 12px;
}

.Variants__table-responsive,
.Variants__table {
  border: 1px solid #d5d7e2;
  border-radius: 3px;
  font-weight: 300;
  line-height: 1;
  width: 100%;
}

.Variants__table-responsive > .Variants__table {
  border: none;
}
.Variants__table .short-column {
  width: 100px;
}
.Variants__table .large-column {
  min-width: 165px;
}
.Variants__table .form-control {
  max-width: 100%;
}
.Variants__table .form-group {
  margin-bottom: 0px;
}
.Variants__table > thead {
  border-bottom: 1px solid #d5d7e2;
}
.Variants__table th:first-child {
  padding-left: 20px;
}
.Variants__table th {
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 300;
  text-align: center;
}
.Variants__table td {
  padding: 19px 3px;
  text-align: center;
}
.Variants__table td:first-child {
  padding-left: 20px;
}
.Variants__table td:last-child {
  padding-right: 20px;
}
.Variants__table td.Variants__table__text {
  max-width: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

th .column-button {
  cursor: pointer;
}

.ql-toolbar.ql-snow {
  border: 1px solid #d5d7e2;
  background-color: #eff0f5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: none;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}
.ql-container.ql-snow {
  border: 1px solid #d5d7e2;
  background-color: #eff0f5;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ql-editor {
  padding: 10px 10px;
  padding-top: 0;
  height: 190px;
}

.tgl-info {
  line-height: 12px !important;
  margin-top: 8px !important;
}

.jodit_toolbar {
  background: #eff0f5 !important;
}

.jodit_container:not(.jodit_inline) {
  background: #eff0f5 !important;
}

.jodit_statusbar {
  background-color: #eff0f5 !important;
}
