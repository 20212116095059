// UI

//// Colors
$purple-base: #8144e5 !default;
$purple-base-9: rgba(129, 68, 229, 0.9) !default;
$orange-warning: #ffa51d;
$green-success: #33cd5d;
$red-danger: #e24444;
$purple-low: #e9e6ff;
$spocket-white: #ffffff !default;
$spocket-grey: #f2f2fa !default;
$spocket-grey-base: #413951 !default;
$spocket-grey-medium: #dadde0 !default;
$spocket-grey-dark: #7e768f !default;
$spocket-gray91: #e8e8e8 !default;

//// Button
$btn_primary-color: $purple-base !default;
$btn_primary-bg: $spocket-white !default;

$btn_default-color: $spocket-grey !default;
$btn_default-bg: $spocket-white !default;

$btn_border-radius-base: 3px;
$btn_font-weight-base: 500;

$btn_border: 1px;

/// Checkbox
$checkbox-border-radius-small: 1px;

/// Input
$input_font-weight-base: 500;

/// Fonts
$font-size-base: 14px;
$font-size-small: 12px;

// Modal

$spocket-black: #000000;
$spocket-success: #2ec457;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$title-color: #413951;
$text-color: #7e768f;

$brand-primary: darken(#428bca, 6.5%); // #337ab7
$brand-success: $spocket-success;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal !default;

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: $btn-success-bg;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $btn-info-bg;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $btn-warning-bg;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg;

$btn-link-disabled-color: $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: 2px;
$btn-border-radius-large: 2px;
$btn-border-radius-small: 2px;

// $brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
// $brand-success:         $spocket-success;
// $brand-info:            #5bc0de !default;
// $brand-warning:         #f0ad4e !default;
// $brand-danger:          #d9534f !default;
