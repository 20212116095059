.clean-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.clean-list li {
  margin-bottom: 10px;
}
.clean-list li:last-child {
  margin-bottom: 0;
}
.clean-list li a {
  font-size: 15px;
  color: rgba(91, 102, 118, 1);
  line-height: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
}
.clean-list li a img {
  width: 16px;
  height: 16px;
  margin-right: 18px;
  float: left;
  opacity: 1;
}
.clean-list li a:hover {
  opacity: 1;
  color: rgba(91, 102, 118, 0.8);
}
.clean-list li a.active {
  opacity: 1;
  background-color: rgb(239, 240, 245);
}
