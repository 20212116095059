.centered {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.centered p {
  font-weight: 700;
}
.centered .logo {
  height: 40px;
  -webkit-filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.05));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#444')";
}
.raised-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.sp-form fieldset {
  position: relative;
}
.sp-form input {
  width: 100%;
  height: 38px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.2s cubic-bezier(0.3, 0.1, 0.58, 1);
  margin-top: 10px;
}
.sp-form input:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.sp-form fieldset:first-of-type input {
  margin-top: 0;
}
.sp-form .help-block {
  color: #e83339;
}

.sp-form button {
  width: 100%;
}

.alert-danger {
  background-color: #e83339;
  color: #fff;
  border: none;
}
.alert-success {
  background-color: #228b22;
  color: #fff;
  border: none;
}
.btn-login {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.captcha div {
  left: 0;
  right: 0;
  margin: auto;
}
