.cancel-order-modal__container .modal-sm {
  min-width: 460px;
}
.cancel-order-modal__title {
  font-weight: 500 !important;
}
.cancel-order-modal__title img {
  margin-right: 15px;
}

.cancel-order-modal__footer {
  background-color: #f2f2fa;
  display: flex;
  justify-content: flex-end;
  margin: -24px;
  padding: 24px 24px 24px 24px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: 15px;
}
.cancel-order-modal__footer button {
  height: 36px !important;
  font-weight: 600 !important;
}

.cancel-order-modal__radio {
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 7px;
  display: flex;
  align-items: center;
  /* padding: 14px 0; */
  margin: 0;
  margin-bottom: 5px;
}

.cancel-order-modal__list-radio label {
  margin-bottom: unset;
  padding: 15px;
  width: 100%;
  font-weight: 500;
}

.cancel-order-modal__radio:hover {
  background-color: #f3efff;
}
.cancel-order-modal__radio-checked {
  background-color: #f3efff;
}
