@import "../abstracts/_variables.scss";

// Texts
%themes__text_title {
  color: $title-color !important;
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
  font-weight: bold !important;
}

%themes__text_description {
  color: $text-color !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  font-family: Avenir-Roman, Avenir, Nunito !important;
}

// Modal
%themes__modal_backdrop_black {
  background: $spocket-black !important;
  opacity: 0.9 !important;
}

%themes__modal_backdrop_white {
  background: $spocket-black !important;
  opacity: 0.2 !important;
}

// Tooltip
%themes__tooltip {
  background-color: $title-color !important;
  width: 12px !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  color: $spocket-white !important;
  margin-left: 5px !important;
  margin-top: 4px !important;
  display: inline-block !important;
  text-align: center !important;
  border-radius: 10px !important;
  cursor: default !important;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
