.popover {
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.popover-title {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.popover.bottom > .arrow {
  border-bottom-color: #ddd !important;
}

.tiny-container {
  display: block;
}

.popover input,
.popover select {
  height: 30px;
}

.popover .btn {
  width: 70px !important;
  margin-left: 0px !important;
}
