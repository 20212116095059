$icon-font-path: 'https://s3-us-west-2.amazonaws.com/assets.spocket.co/components/dist/fonts/bootstrap/';

@import './abstracts/_variables.scss';
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

.listing_details_modal__container {
  .modal-content {
    width: 956px !important;
    margin-left: -28px !important;
    background-color: transparent !important;
    margin-top: 30px !important;
    box-shadow: none !important;
    hr {
      border-top: 1px solid #eeeeee !important;
    }
  }
}

@media (max-width: 990px) {
  .listing_details_modal__container {
    .modal-content {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}

.agree_modal__container {
  font-family: Avenir, Nunito, sans-serif;
  .modal-dialog {
    width: 444px !important;
  }
}

.discount-stamp {
  height: 30px;
  background: #8144e5;
  position: absolute;
  width: auto;
  z-index: 3;
  right: 16px;
  top: 262px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  padding-left: 0;

  img {
    height: 16px;
    margin-right: 5px;
  }
}

.discount-stamp::before {
  content: '';
  position: absolute;
  top: 0px;
  left: -16px;
  border-top: 15px solid transparent;
  border-right: 16px solid #8144e5;
  border-bottom: 15px solid transparent;
}

.badge-premium-listing {
  background-image: url('https://s3-us-west-2.amazonaws.com/assets.spocket.co/components/dist/assets/premiumIcon.svg') !important;
  background-repeat: no-repeat;
  background-size: 16px !important;
  background-color: white !important;
  height: 30px !important;
  width: auto !important;
  border-radius: 100px !important;
  font-size: 12px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
  background-position: 10% 50%;
  padding: 8px;
  padding-left: 34px;
  text-align: right;
  padding-right: 10px;
  font-weight: 500;
  position: absolute;
  bottom: 12px;
  z-index: 3 !important;
}

.badge-hot-listing {
  background-image: url('https://s3-us-west-2.amazonaws.com/assets.spocket.co/components/dist/assets/hotProductIcon.svg') !important;
  background-repeat: no-repeat;
  background-size: 16px !important;
  background-color: white !important;
  height: 30px !important;
  width: 56px !important;
  border-radius: 100px !important;
  font-size: 12px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
  background-position: 10% 50%;
  padding: 8px;
  text-align: right;
  padding-right: 10px;
  font-weight: 500;
  position: absolute;
  top: 280px;
  z-index: 10 !important;
}

.badge-premium-product {
  background: url('https://s3-us-west-2.amazonaws.com/assets.spocket.co/components/dist/assets/premiumIcon.svg')
    no-repeat center !important;
  background-size: 20px !important;
  background-color: #000932 !important;
  height: 36px !important;
  width: 30px !important;
  border-radius: 0 0 3px 3px !important;
}

.btn-spocket-primary {
  border-radius: 2px !important;
  background-color: #8144e5 !important;
  color: #8144e5 !important;
  box-shadow: none !important;
}
.btn-spocket-primary:hover {
  background-color: #504aa5 !important;
}

.btn-spocket-info {
  border-radius: 2px !important;
  border: solid 2px #625ac9;
  border-radius: 2px !important;
  background-color: white !important;
  color: #625ac9 !important;
}
.btn-spocket-info:hover {
  background-color: #f4f3ff !important;
}

.btn-spocket-warning {
  border-radius: 2px !important;
  border: solid 2px #ffa51d !important;
  border-radius: 2px !important;
  background-color: #ffa51d !important;
  color: white !important;
  box-shadow: none !important;
}

.btn-spocket-danger {
  border-radius: 2px !important;
  border: solid 2px #f7324a !important;
  border-radius: 2px !important;
  background-color: #f7324a !important;
  color: white !important;
  box-shadow: none !important;
}
.btn-spocket-default {
  border-radius: 2px !important;
  border: solid 1px #dadde0 !important;
  border-radius: 2px !important;
  background-color: #f2f2fa !important;
  color: #413951 !important;
  box-shadow: none !important;
}

.error {
  color: #f7324a;
  margin: 5px 0 10px 0;
}

.warning {
  color: #ffa51d;
}
