.resource-list-item__image {
  background-position-x: center;
  background-size: cover;
  cursor: pointer;
  height: 170px;
  margin: 0px 20px 12px 20px;
}

.card {
  border: 0px !important;
}

.card-title {
  font-size: 22px;
  color: #13283c;
  margin-bottom: 6px;
}

.card-description {
  font-size: 16px;
  color: #778fa7;
}
