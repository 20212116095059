.add-img {
  width: 126px;
  display: inline-block;
  border: 1px solid #d5d7e2;
  border-radius: 4px;
  text-align: center;
  line-height: 124px;
  float: left;
  cursor: pointer;
  background-color: #eff0f5;
}

.has-error .add-img {
  border: 1px solid #f44336;
}

.add-img img {
  width: 25px;
  height: 25px;
}
