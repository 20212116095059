.title-header.Dashboard__title {
  margin-bottom: 30px;
}

.embed-responsive {
  border-radius: 5px;
}
.owl-carousel .owl-item img {
  display: inline-block;
}
.article-title {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#tutorials-tab-first,
#tutorials-tab-second,
#tutorials-tab-third,
#tutorials-tab-fourth,
#tutorials-tab-fifth,
#tutorials-tab-sixth,
#tutorials-tab-seventh {
  background: white;
  border-bottom: 1px solid #eff0f6;
  color: #000726;
  font-weight: 400;
  line-height: 14px;
  font-size: 15px;
  padding: 16px 20px 16px 45px;
  text-align: left;
  text-transform: none;
}

#tutorials-tab-first[aria-selected='true'],
#tutorials-tab-second[aria-selected='true'],
#tutorials-tab-third[aria-selected='true'],
#tutorials-tab-fourth[aria-selected='true'],
#tutorials-tab-fifth[aria-selected='true'],
#tutorials-tab-sixth[aria-selected='true'],
#tutorials-tab-seventh[aria-selected='true'] {
  background: #eff0f5;
  color: #000726;
  font-weight: 500;
  box-shadow: none;
}

#tutorials-tab-first[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-second[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-third[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-fourth[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-fifth[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-sixth[aria-selected='true'] .tutorials_display__triangle-active,
#tutorials-tab-seventh[aria-selected='true'] .tutorials_display__triangle-active {
  display: none;
}

[role='presentation'] {
  margin: 0 !important;
}

.tutorials_display__triangle {
  border-bottom: 7px solid transparent;
  border-left: 10px solid #6342ff;
  border-top: 7px solid transparent;
  height: 0;
  padding: 0;
  position: absolute;
  width: 0;
  left: 15px;
}

.tutorials_display__triangle-active {
  border-bottom: 5px solid transparent;
  border-left: 7px solid white;
  border-top: 5px solid transparent;
  height: 0;
  padding: 0;
  position: absolute;
  width: 0;
  left: 16px;
  bottom: 18px;
}

.resources_display__card {
  background: white;
  padding-top: 16px;
  box-shadow: 0px 0px 2px #aaaaaa;
}
