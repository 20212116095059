.added-img {
  width: 126px;
  height: 126px;
  display: inline-block;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin-right: 7px;
  line-height: 124px;
}
